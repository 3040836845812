/* flex container */
.pet-display-extra {
  width: 40vw;
  top: 0;
  height: auto;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
  background-color: rgb(17, 105, 132);
  padding: 10px;
}

.pet-display {
  display: flex;
  top: 0;
  flex: auto;
  justify-content: center;
  align-items: center;
  height: fit-content;
  max-height: max-content;
}

/* .Board {
  width: 100%;
  height: 500px;
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
  justify-self: center;
} */

.p-sprite-display {
  display: flex;
  justify-content: center;
  max-width: 100%;
}

.bg-sprite {
  position: relative;
  flex: 1 1 auto;
  width: 0;
  height: auto;

  /* top: 0;
    left: 0; */
  /* width: auto;
    height: auto; */
  /* border: 1px solid #000000; */
}

.bg-sprite-mobile {
  position: relative;
  flex: 1 1 auto;
  width: 0;
  height: auto;

  /* top: 0;
    left: 0; */
  /* width: auto;
    height: auto; */
  /* border: 1px solid #000000; */
}

.p-sprite {
  /* width: 50%; */
  position: absolute;
  width: 205px;
  height: 290px;
  /* top: 30px;
    left: 30px; */
  top: 220px;
  /*width: 50%;
    /* border: 1px solid #000000; */
}

/* https://www.dafont.com/bitmap.php?page=2 */
@font-face {
  font-family: pixelFont;
  src: url("../../fonts/retrogaming.ttf");
}

.pet-name {
  font-size: calc(0.8vw + 1.4vh);
  padding: 15px 0px 5px 0px;
  text-align: center;
  font-weight: bold;
  font-family: pixelFont;
}

.pbar-exp {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* justify-content: center; */

  padding: 10px;
  border-style: solid none;
  border-width: 1.5px;
  border-color: gray;
  width: 100%;
}

.pd-position,
.pbar-exp {
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.pet-desc {
  border-radius: 0px 0px 15px 15px;
  width: 100%;
  height: auto;
}

.pet-desc-text {
  font-size: calc(0.7vw + 1.4vh);
  padding: 5px;
  margin: 5px;
  text-align: left;
  height: 15vh;
}

/* EXP RATIO  */
.experience-ratio {
  font-family: pixelFont;
  font-size: calc(0.4vw + 1vh);
}

/* LEVEL text for MOBILE */
.pbar-text-mobile {
  font-size: calc(0.7vw + 1.3vh);
  font-weight: bold;
  padding: 0px 5px;
  font-family: pixelFont;
}

/* text for LEVEL, MOOD, and WEIGHT */
.pet-label,
.pbar-text {
  font-size: calc(0.6vw + 1vh);
  font-weight: bold;
  padding: 0px 10px;
  font-family: pixelFont;
}

.pd-bg {
  background-color: rgb(237, 237, 237);
}

.pet-display .card {
  border-style: none;
  /* border-color: black; */
  border-radius: 0px;
  border-width: 1px;
  /* background-color: black; */
}

.progress {
  border-radius: 5px;
  border-style: solid;
}
.pet-display-mobile {
  display: flex;
  flex-direction: column;
  /* max-height: 60vh; */
  /* height: 50vh; */
}
.p-sprite-display-mobile {
  width: 100%;
  max-height: 400px;
  align-items: center;
}

/* LEVEL, EXP BAR, EXP RATIO mobile */
.mobileBar {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}
.pet-container-mobile {
  background-image: url("../../images/bg.gif");
  /* background-size: 50vh; */
  width: 80%;
  /* max-height: 50vh; */
  /* height: 50vh; */
  /* height: 60%; */
  height: calc(100vh - 350px - 70%);
  /* height: calc(100vh - 60vh); */
  background-size: cover;
  /* background-size: ; */
  background-position: center center;
  justify-content: center;
  /* width:50vh; */
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}

.sprite-sheet-mobile {
  width: 70%;
  /* max-height: calc(100vh - 550px - 70%); */
  max-height: 38vh;
  top: 35px;
  /* max-height: calc(100vh - 60vh - 30%); */
  /* max-height: 50vh; */
  justify-content: center;
  position: relative;
  margin: 15%;
  /* margin: auto; */
}

.progress-bar[aria-valuenow="0"] {
  transition: none;
}
