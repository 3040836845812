@font-face {
  font-family: pixelFont;
  src: url("../../fonts/retrogaming.ttf");
}

.alice-container {
  max-height: 100%;
  height: 100vh;
  background-color: rgb(255, 198, 198);
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
}

.padding-container {
  height: auto;
  margin: auto;
  /* padding-bottom: 10px; */
  background-color: rgb(255, 198, 198);
  bottom: 0;
}
/*
.alice-carousel {
  height: auto;
  flex-direction: column;
  background-color: rgb(255, 198, 198);
  max-height: 90%;
}*/

.alice-box {
  height: auto;
  max-height: 90vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.tut-image {
  text-align: center;
  display: block;
  margin: auto;
  width: auto;
  max-height: 70vh;
  max-width: 100%;
  border-style: outset;
  height: auto;
}

.header-tut {
  top: 0;
  background-color: bisque;
  border: rgb(33, 33, 33);
  max-width: 95vw;
  margin-left: auto;
  margin-right: auto;
  border-width: 5px;
  border-style: double;
  /* padding-left: 10px;
  padding-right: 10px; */
}

.header-text-tut {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: pixelFont;
  font-weight: bold;
  font-size: 18px;
}

.tut-imagebox {
  text-align: center;
  display: block;
  margin: auto;
  width: auto;
  max-height: 75vh;
  max-width: 100%;
}

.tut-text {
  max-width: 100px;
  font-family: pixelFont;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  text-align: center;
  justify-self: center;
}

.tut-textbox {
  justify-items: center;
  text-align: center;
  justify-self: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-width: 5px;
  border-style: double;
  /* padding-left: 10px;
  padding-right: 10px; */
  font-size: 14px;
  background-color: antiquewhite;
  max-width: 95vw;
}
@media (min-height: 667px) {
  .alice-container {
    max-height: 100vh;
    min-height: 100vh;
    height: auto;
    background-color: rgb(255, 198, 198);
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
  }
}

/*Mary's Phone*/
@media (min-height: 800px) {
  .alice-container {
    max-height: 90%;
    height: auto;
    background-color: rgb(255, 198, 198);
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    bottom: 0;
  }

  .tut-image {
    text-align: center;
    height: auto;
    display: block;
    margin: auto;
    width: auto;
    max-height: 60vh;
    /*padding-top: 70px;*/
    /* padding-bottom: 20px;*/
    max-width: 100%;
    border-style: ridge;
  }

  .tut-imagebox {
    text-align: center;
    display: block;
    margin: auto;
    width: auto;
    max-height: 70vh;
    max-width: 100%;
    /* padding-top: 2vh;
    padding-bottom: 20vh; */
  }
}


.tut-card-about {
  background-color: rgb(255, 214, 214);
  flex: 1 1 auto;
  width: 90vw;
  height: 280px;
  margin-top: -10px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  overflow-wrap: break-word;
}

.tut-card-title-mobile {
  font-size: 20px;
  margin-top: 10px;
  font-family: pixelFont;
}

.tut-card-body-mobile {
  margin-top: -10px;
  overflow: hidden;
  width: 90vw;
  font-family: pixelFont;
}

.tut-submit-button {
  cursor: pointer;
  /* padding: 8px;
  margin: 5px; */
  background: rgb(172, 65, 100);
  color: #ffffff;
  border-radius: 10px;
  font-size: 20px;
  transition: all 0.1s ease-in;
  position: relative;
}

.tut-image-kitty-mobile {
  margin-left: auto;
  margin-right: auto;
  margin-top: -70px;
  height: 300px;
  width: 300px;
  justify-content: center;
}

.tut-landingbutton:hover {
  background: #3a0422;
  color: #ffffff;
}

.proceed-header{
  font-size: 50px;
  font-family: pixelFont;
}

.tut-submit-button{
  font-family: pixelFont;
  color:rgb(255, 214, 214)
}


/*desktop styles*/
@media (min-width: 1130px) {
  .proceed-header{
    font-size: 50px;
    font-family: pixelFont;
  }
  .tut-card-about{

    background-color: rgb(255, 214, 214);
    flex: 1 1 auto;
    max-width: 50vw;
    height: auto;
    max-height: 60vh; 
    margin-top: -10px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    overflow-wrap: break-word;
  }
  .tut-card-title-mobile {
    font-size: 34px;
    font-family: pixelFont;
    margin-top: 10px;
  }
  
  .tut-card-body-mobile {
    margin-top: -10px;
    overflow: hidden;
    max-width: 50vw;
    font-size: 30px;
    font-family: pixelFont;
  }

  .alice-container {
    max-height: 90vh;
    height: 90vh;
    background-color: rgb(255, 198, 198);
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    bottom: 0;
  }


  .header-tut {
    top: 10;
    background-color: bisque;
    border: rgb(33, 33, 33);
    max-width: 30vw;
    margin-left: auto;
    margin-right: auto;

    border-width: 5px;
    border-style: double;

    /* padding-left: 10px;
    padding-right: 10px; */
  }

  .header-text-tut {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: pixelFont;
    font-weight: bold;
    font-size: 18px;
  }

  .alice-carousel {
    flex-direction: column;
    background-color: rgb(255, 198, 198);
    max-height: 100vh;
    height: 100vh;
  }

  .alice-box {
    height: auto;
    max-height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 10px;
  }

  .tut-image {
    text-align: center;
    height: auto;
    display: block;
    margin: auto;
    width: auto;
    max-height: 75vh;
    max-width: 100%;
    border-style: ridge;
  }

  .tut-imagebox {
    text-align: center;
    display: block;
    margin: auto;
    width: auto;

    max-height: 75vh;
    max-width: 100%;
  }

  .tut-textbox {
    justify-items: center;
    text-align: center;
    justify-self: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-width: 5px;
    border-style: double;
    /* padding-left: 10px;
    padding-right: 10px; */
    background-color: antiquewhite;
    max-width: 20vw;
  }
  .tut-submit-button{
    min-width: 20vw;
    min-height: 10vh;
    font-family: pixelFont;
    color:rgb(255, 214, 214)
  }

  .tut-card-about-mobile {
    background-color: rgb(255, 214, 214);
    flex: 1 1 auto;
    width: 90vw;
    height: 280px;
    margin-top: -10px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    overflow: hidden;
  }

  .tut-card-title-mobile {
    font-size: 20px;
    margin-top: 10px;
  }

  .tut-card-body-mobile {
    margin-top: -10px;
    overflow: hidden;
  }
}

html,
body {
    background-color: rgb(255, 198, 198)
}
