
.Footer {

    /* position: absolute; */
    bottom: 0;
    height:100px;
    width: 100%;
    
    display: flex;
    justify-content: left;
    align-items: center;

    padding: 1em;
    background-color: rgb(22, 22, 22);
    color: #fff;
  }

  @media (max-width: 1130px) {
    .Footer {
      display: none;    
    }
  }  
  
.FooterText {
    padding: 1px;
    margin: 1px;
}

