.container-m {
  display: grid;
  grid-template-rows: 45% 50% 5%;
  grid-template-columns: 100%;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: rgb(255, 198, 198);
  /* grid-template-areas: 'myBottom myBottom ...' 'myBottom myBottom ...'; */
}

.backg {
  /* border-radius: 15px; */
  display: flex;
  align-items: center;
  position: relative;

  /* IMPORTANT:KEEP AS MIN HEIGHT NOT HEIGHT*/
  /* https://www.freecodecamp.org/news/how-to-keep-your-footer-where-it-belongs-59c6aa05c59c/ */
  min-height: 100vh;
  background-color: rgb(255, 198, 198);
  /* padding: 0px 5px; */
}

.birthday-form {
  margin-top: 20px;
}

.label-bio-register {
  margin-top: 20px;
}

.label-first-name-register {
  margin-top: 20px;
}

.tab-container {
  position: fixed;
  padding-bottom: calc(env(safe-area-inset-bottom) + 16px);
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: space-evenly;
  background-color: rgb(255, 198, 198);
  /* height: 6vh; */
  grid-row-start: 3;
  grid-row-end: 3;
}

.tab {
  width: 100%;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  flex: 1;
}

.tab.active {
  background-color:rgb(250, 146, 146);
}

.top-container-mobile {
  grid-row-start: 1 ;
  grid-row-end:2;
  /* margin-top: 400px; */
  position: relative;
  /* justify-content: space-between; */
  /* height: 47vh; */
  width: 100vw;
  /* height: auto; */

  
  
  /* height: 300px; */
  background-color: pink;
  background: size 100px;
  /* margin-bottom: 55px; */
  margin-left: 0px;
  overflow: auto;
}

.bottom-container-mobile {

  grid-row-start: 2 ;
  grid-row-end: 3;
  position: relative;
  /* justify-content: space-between; */
  /* height: 48vh; */
  overflow:auto;

  /* height: auto; */

  
  
  /* height: 300px; */
  background-color: white;
  background: size 100px;
  /* margin-bottom: 55px; */
}

.container-canvas-logo{
  flex: 1;
  max-width: 20vw;
  display: flex;
  height: auto;
  background-color: transparent;
  border: transparent;
  justify-content: center;
  align-items: center;
}

.canvas-loading {
  width: 35px;
  height: 35px;
  justify-self: center;
  animation: bounce 3000ms;
  animation-iteration-count: infinite;
}

.logo-canvas {
  width: 35px;
  height: 35px;
  justify-content: center;
  align-items: center;
}
