.Candy {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.row {
  float: left;
  position: relative;
  width: 100%;
}

img {
  margin: 5px;
}

.Wrapper {
  padding: 20;
  border: "5px solid black";
  height: 200;
  width: 1040;
}

.Board {
  width: 500px;
  height: 500px;
  /* border: 2px solid black; */
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
  justify-self: center;
}

.Candy-Quantity {

  top: -0.75em;
  right: -0.75em;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 50%;
  background: aquamarine;
  color: darkblue;
}

.inventory-header {
  font-weight: bold;
  font-size: calc(2vw+.4vw);
}

.inventory-box {
  padding: 10;
  border-radius: 5px;
  border-color: black;

  /* height: 600; 
  width: 910;
  margin: 100;
  background-color: "pink";
  overflow: scroll; */

}

/* Wrap around all of the inventory, enables scrolling, etc */
.inventory-wrapper {
  /* min-height: 85vh; */
  max-height: 90vh;
  border-radius: 0px;

  margin-bottom: 0px;
  /* padding: 50px; */
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.inventory-wrapper-mobile {
  /* min-height: 85vh;
  max-height: 85vh; */
  border-radius: 0px;
  /* height: 50vh;
  width: 50vw; */

  /* margin-bottom: 10px; */
  /* padding: 20px; */
  /* overflow: scroll;
  -webkit-overflow-scrolling: touch; */
}


/* Makes the candies appears in a grid */
.inventory-wrapper-sizes {

  display: grid;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;

  /* grid-template-columns: auto auto auto auto auto; */
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows */
  /* grid-template-columns: repeat(auto-fill, 180px); */
  grid-template-columns: 1fr 1fr 1fr 1fr;

  grid-auto-rows: 170px;



}

.inventory-wrapper-sizes-mobile {

  display: grid;
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
  

  /* grid-template-columns: auto auto auto auto auto; */
  /* https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows */
  /* grid-template-columns: repeat(auto-fill, 70px); */
  grid-template-columns: 1fr 1fr 1fr 1fr;

  grid-auto-rows: 78px;
  overflow: hidden;
  /* height: 20px;
  width: 20px; */

}

/* Iphone SE 2nd Gen */
@media only screen and (min-height: 667px) { 
  .inventory-wrapper-sizes-mobile {

    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 30px;
    padding-right: 30px;
    /* overflow: scroll; */
    
  
    /* grid-template-columns: auto auto auto auto auto; */
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows */
    grid-template-columns: repeat(auto-fill, 65px);
  
    grid-auto-rows: 60px;
    /* overflow: hidden; */
    /* padding-top: -30px;
    padding-bottom: -30px; */
    /* height: 20px;
    width: 20px; */
  
  }
}

/* iphone 12 jay */
@media only screen and (min-width: 390px) {
  .inventory-wrapper-sizes-mobile {

    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    /* padding: auto; */
    /* padding-left: 30px;
    padding-right: 30px; */
    /* overflow: scroll; */
    
  
    /* grid-template-columns: auto auto auto auto auto; */
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows */
    /* grid-template-columns: repeat(auto-fill, 65px); */
    grid-template-columns: 1fr 1fr 1fr 1fr;
  
    grid-auto-rows: 65px;
    /* overflow: hidden; */
    /* padding-top: -30px;
    padding-bottom: -30px; */
    /* height: 20px;
    width: 20px; */
  
  }
}


/* Iphone 12 Pro Max ShoutOut Mary */
/* @media only screen and (min-height: 926px) { */
@media only screen and (min-width: 428px) {
  .inventory-wrapper-sizes-mobile {

    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    
  
    /* grid-template-columns: auto auto auto auto auto; */
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows */
    /* grid-template-columns: repeat(auto-fill, 90px); */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 10px;
    padding-bottom: 10px;

  
    grid-auto-rows: 65px;
    /* overflow: hidden; */
    /* padding-top: 14px;
    padding-bottom: 14px; */
    /* height: 20px;
    width: 20px; */
  
  }
}

/* Ipad */
@media only screen and (min-height: 1080px) {
  .inventory-wrapper-sizes-mobile {

    display: grid;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    
  
    /* grid-template-columns: auto auto auto auto auto; */
    /* https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows */
    grid-template-columns: repeat(auto-fill, 120px);
  
    grid-auto-rows: 90px;
    overflow: hidden;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 130px;
    padding-right: 130px;
    /* height: 20px;
    width: 20px; */
  
  }
}



/*  Wrap around badge AND grid item */
.grid-wrapper {
  position: relative;
  text-align: center;
  font-size: 30px;
  
  width: 130px;
  height: 130px;

  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

  /*Need these two for sure */
  -webkit-touch-callout: none;
  user-select: none;  /* supported by Chrome and Opera */
  /*-webkit-user-select: none; /* Safari */
  /*-khtml-user-select: none; /* Konqueror HTML */
  /*-moz-user-select: none; /* Firefox */
  /*-ms-user-select: none; /*Internet Explorer/Edge */ 


}

/*  Wrap around badge AND grid item */
.grid-wrapper-mobile {
  position: relative;
  text-align: center;
  font-size: 30px;
  
  /* width: 100vw;
  height: 100vh; */

  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

  /*Need these two for sure */
  -webkit-touch-callout: none;
  user-select: none;  /* supported by Chrome and Opera */
  /*-webkit-user-select: none; /* Safari */
  /*-khtml-user-select: none; /* Konqueror HTML */
  /*-moz-user-select: none; /* Firefox */
  /*-ms-user-select: none; /*Internet Explorer/Edge */ 


}


/*  */
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 3px solid rgba(255, 192, 203, 0);
  box-shadow: 0 5px 15px -4px rgb(65, 65, 65);
  border-radius: 20px;

  /* https://stackoverflow.com/questions/14350126/transition-color-fade-on-hover */
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;

  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item:hover {
  border: 3px solid rgba(33, 33, 33, 0.747);
}
.grid-item-mobile {
  background-color: rgba(255, 255, 255, 0.8);
  border: 3px solid rgba(255, 192, 203, 0);
  box-shadow: 0 5px 15px -4px rgb(65, 65, 65);
  border-radius: 20px;

  /* https://stackoverflow.com/questions/14350126/transition-color-fade-on-hover */
  -moz-transition: all .2s ease-in;
  -o-transition: all .2s ease-in;
  -webkit-transition: all .2s ease-in;
  transition: all .2s ease-in;

  width: 55px;
  height: 55px;
  
  /* width: calc(15vw + 15vh);
  height: calc(15vw + 15vh); */
  
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Iphone SE 2nd Gen */
@media only screen and (min-height: 667px) {
  .grid-item-mobile {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(255, 192, 203, 0);
    box-shadow: 0 2px 10px -2px rgb(65, 65, 65);
    border-radius: 20px;
  
    /* https://stackoverflow.com/questions/14350126/transition-color-fade-on-hover */
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
  
    width: 55px;
    height: 55px;
    
    /* width: calc(15vw + 15vh);
    height: calc(15vw + 15vh); */
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* iphone 12 jay */
@media only screen and (min-width: 390px) {
  .grid-item-mobile {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(255, 192, 203, 0);
    box-shadow: 0 2px 10px -2px rgb(65, 65, 65);
    border-radius: 20px;
  
    /* https://stackoverflow.com/questions/14350126/transition-color-fade-on-hover */
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
  
    width: 70px;
    height: 70px;
    
    /* width: calc(15vw + 15vh);
    height: calc(15vw + 15vh); */
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Iphone 12 Pro Max ShoutOut Mary */
/* @media only screen and (min-height: 926px) { */
  @media only screen and (min-width: 428px) {
  .grid-item-mobile {
    background-color: rgba(255, 255, 255, 0.8);
    border: 3px solid rgba(255, 192, 203, 0);
    box-shadow: 0 5px 10px -4px rgb(65, 65, 65);
    border-radius: 20px;
  
    /* https://stackoverflow.com/questions/14350126/transition-color-fade-on-hover */
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
  
    width: 75px;
    height: 75px;
    
    /* width: calc(15vw + 15vh);
    height: calc(15vw + 15vh); */
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Ipad */
@media only screen and (min-height: 1080px) {
  .grid-item-mobile {
    background-color: rgba(255, 255, 255, 0.8);
    border: 3px solid rgba(255, 192, 203, 0);
    box-shadow: 0 5px 10px -4px rgb(65, 65, 65);
    border-radius: 20px;
  
    /* https://stackoverflow.com/questions/14350126/transition-color-fade-on-hover */
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
  
    width: 90px;
    height: 90px;
    
    /* width: calc(15vw + 15vh);
    height: calc(15vw + 15vh); */
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.candy-photo {
  width: 80px;
  height: auto;
  pointer-events: none;
  /* user-select: none; */
  /*-webkit-user-select: none; /* Safari */
  /*-khtml-user-select: none; /* Konqueror HTML */
  /*-moz-user-select: none; /* Firefox */
  /*-ms-user-select: none; /*Internet Explorer/Edge*/
  
  
}

.candy-photo-mobile {
  width: 40px;
  height: auto;
  pointer-events: none;
  /* user-select: none; */
  /*-webkit-user-select: none; /* Safari */
  /*-khtml-user-select: none; /* Konqueror HTML */
  /*-moz-user-select: none; /* Firefox */
  /*-ms-user-select: none; /*Internet Explorer/Edge*/
  
  
}
/* iphone se */
@media only screen and (min-height: 667px) {
  .candy-photo-mobile {
    width: 45px;
    height: auto;
    pointer-events: none;
    /* user-select: none; */
    /*-webkit-user-select: none; /* Safari */
    /*-khtml-user-select: none; /* Konqueror HTML */
    /*-moz-user-select: none; /* Firefox */
    /*-ms-user-select: none; /*Internet Explorer/Edge*/
    
  }
}
/* iphone 12 jay */
@media only screen and (min-width: 390px) {
  .candy-photo-mobile {
    width: 60px;
    height: auto;
    pointer-events: none;
    /* user-select: none; */
    /*-webkit-user-select: none; /* Safari */
    /*-khtml-user-select: none; /* Konqueror HTML */
    /*-moz-user-select: none; /* Firefox */
    /*-ms-user-select: none; /*Internet Explorer/Edge*/
    
  }
}


/* Iphone 12 Pro Max ShoutOut Mary */
/* @media only screen and (min-height: 926px) { */
  @media only screen and (min-width: 428px) {
  .candy-photo-mobile {
    width: 55px;
    height: auto;
    pointer-events: none;
    /* user-select: none; */
    /*-webkit-user-select: none; /* Safari */
    /*-khtml-user-select: none; /* Konqueror HTML */
    /*-moz-user-select: none; /* Firefox */
    /*-ms-user-select: none; /*Internet Explorer/Edge*/
    
    
  }
}

/* Ipad */
@media only screen and (min-height: 1080px) {
  .candy-photo-mobile {
    width: 80px;
    height: auto;
    pointer-events: none;
    /* user-select: none; */
    /*-webkit-user-select: none; /* Safari */
    /*-khtml-user-select: none; /* Konqueror HTML */
    /*-moz-user-select: none; /* Firefox */
    /*-ms-user-select: none; /*Internet Explorer/Edge*/
  
  }
}


.candy-wrapper {
  width: 130px;
  height: 130px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.candy-wrapper-mobile {
  width: 40px;
  height: 40px;
  /* padding: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.text-wrapper {
  /* display: flex;
  justify-content: center; 
  align-items: center;  */
  font-size: calc(1vw+.4vw);
  font-weight: bold;
  /* text-decoration: underline; */
  
}

.candy-q {
  width: 40px;
  height: 40px;
  padding: 8px;
  position: absolute;
  top: -15px;
  right: -15px;
  pointer-events: none;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

}

.candy-q-mobile {
  font-size: 14px;
  width: 26px;
  height: 26px;
  padding: 4.7px;
  position: absolute;
  top: -1.5px;
  right: -3.0px;
  pointer-events: none;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

}
/* Iphone 12 SE 2nd Gen */
@media only screen and (min-height: 667px) {
  .candy-q-mobile {
    font-size: 12px;
    width: 22px;
    height: 22px;
    padding: 4.7px;
    position: absolute;
    top: -1.5px;
    right: -7px;
    pointer-events: none;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  
  }
}
/* iphone 12 jay */
@media only screen and (min-width: 390px) {
  .candy-q-mobile {
    font-size: 12px;
    width: 22px;
    height: 22px;
    padding: 4.7px;
    position: absolute;
    top: -1.5px;
    right: -7px;
    pointer-events: none;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  
  }
}
/* Iphone 12 Pro Max ShoutOut Mary */
/* @media only screen and (min-height: 926px) { */
@media only screen and (min-width: 428px) {
  .candy-q-mobile {
    font-size: 14px;
    width: 26px;
    height: 26px;
    padding: 4.7px;
    /* padding-top: -4px; */
    position: absolute;
    top: -1.5px;
    right: -3.5px;
    pointer-events: none;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  
  }
}
/* Ipad */
@media only screen and (min-height: 1080px) {
  .candy-q-mobile {
    font-size: 20px;
    width: 32px;
    height: 32px;
    padding: 4.5px;
    /* padding-top: -4px; */
    position: absolute;
    top: -4.5px;
    right: -10px;
    pointer-events: none;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  
  }
}



.space-candy-types {
  margin-bottom: 40px;
}

@-webkit-keyframes fill { 
  to {
  background-size: 100% 0; 
  }
} 
  
@keyframes fill { 
to { 
background-size: 100% 0;
}
}
  
/* The wrapper has the .cnh_holding class while the button is being pressed */
  
.cnh_holding .grid-item {
background: -webkit-linear-gradient( white , white) rgb(255,215,235) no-repeat 0 0;
background: linear-gradient( white , white) rgb(255,215,235) no-repeat 0 0;
mix-blend-mode: multiply;
background-size: 100% 100%;
-webkit-animation: fill 2s forwards;
animation: fill 2s forwards;
}

.cnh_holding .grid-item-mobile {
  background: -webkit-linear-gradient( white , white) rgb(255,215,235) no-repeat 0 0;
  background: linear-gradient( white , white) rgb(255,215,235) no-repeat 0 0;
  mix-blend-mode: multiply;
  background-size: 100% 100%;
  -webkit-animation: fill 2s forwards;
  animation: fill 2s forwards;
  }