.header {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 3vw;
    padding: 5px;
    margin: 5px;
    text-align: center;
}

.text {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 3vw;
    padding: 5px;
    margin: 5px;
    text-align: center;
}

.body {
    min-height: 100vh;
}

.submit_canvas {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.loading-parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container {
    width: 50vh;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loading-image {
    position: relative;
    width: 20vh;
    height: auto;
    margin: 0 auto;
    animation: spin 2s linear infinite;
}

.canvas_card {
    background-color: rgb(255, 214, 214);
    flex: 1 1 auto;
    width:40vw;
    height: auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
}

@media (max-width: 1130px){
    .canvas_card {
        width:100vw
    }
}

/* .instructions {
    background-color: rgb(255, 214, 214);
    flex: 1 1 auto;
    width: 0;
    height: auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
} */

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}