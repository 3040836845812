.Header {

    /* top: 0;
    box-sizing: border-box;
    width: 100%; */
    top: 0;

    display: flex;
    justify-content: left;
    align-items: center;

    /* 
    padding: 1em;

    
    color: #fff; */
}

@media (max-width: 1130px) {
    .header-whole {
      display: none;    
    }
}   

.header-whole {
    background-color: rgb(33, 33, 33);
}



.logo-sb {
    width: 70px;
    height: auto;
    justify-content: center;
    align-items: center;
}



.header-position {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(33, 33, 33);
}

.header-text {
    padding: 0px 15px;
    font-weight: bold;
    font-size: 20px;
    /* font-family:  pixelFont; */
}

.dropdown-position {
    padding: 0px 35px;
    font-weight: bold;
    font-size: 20px;
    color: #b4e3f0;
    /* font-family:  pixelFont; */
}


.App-link:hover,
.App-link {
    color: #6e7172;
    font-weight: bold;
}

.dropdown-toggle::after {
    content: none;
}



@keyframes bounce {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .container-canvas-logo{
    width: auto;
    height: auto;
    background-color:black;
    justify-content: center;
    align-items: center;
  }
  
  .logo-canvas {
    width: 40px;
    height: 40px;
    color:black;
    justify-content: center;
    align-items: center;
  }

  .canvas-loading {
    width: 40px;
    height: 40px;
    justify-self: center;
    animation: bounce 3000ms;
    animation-iteration-count: infinite;
  }