@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

html {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;

    color: #fff;
}

body {
  
    min-height: 100vh;
    background-color: rgb(255, 255, 255);
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem 0.5rem;
}

.section-login-register {
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
}

.form-login-register {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}

.link-login-register,
.link-login-register:visited {
    color: #fff;
}

input[type="text"],
input[type="password"],
input[type="email"],

.button-login-register,
textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    padding: 0.25rem;
    border-radius: 0.5rem;
}

.label-login-register,
.button-login-register {
    margin-top: 1rem;
}

.button-login-register {
    padding: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

.backg {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}