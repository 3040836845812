/* flex container */
.page-display {
    width: 60vw;
    max-width: 60vw;
    height: 100% ;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    background-color: rgb(255, 198, 198);
    padding: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
}


.pg-tabs.nav-tabs,
.pg-tabs .nav-link {
    background-color: rgba(245, 190, 205, 0.486);
    color: rgb(217, 91, 91);
    font-size: calc(1.3vw + 1vh);
    font-weight: bold;
    margin: 0px;
    /* font-family:  pixelFont; */
    letter-spacing: 0.5px;
    border-width: 0px;

}


.pg-tabs .nav-link.active {
    color: rgb(215, 60, 60);
}

.pg-tabs .nav-link:hover {
    color: rgb(215, 60, 60);
}

.mini-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 0px;
    position: relative;
}



/* make the tabs height the max of the parent */
.tab-content {
    height: 100%;
    background-color: rgb(255, 255, 255);
}


.tasklist-position,
.pet-profile-position {
    flex: 1 1 auto;
    width: 0;
}


.tab-content .calendar-page {
    background-color: rgb(58, 24, 24);
}


.mb-3 {
    margin-bottom: 0px !important;
    
}

.to-do-header {
    font-weight: bold;
    font-size: calc(2vw+.4vw);
}

.pet-profile-header {
    font-weight: bold;
    font-size: calc(3vw+.4vw);
    margin: 0 auto;
    text-align: center;
}
