/* Iphone 12 Pro Max ShoutOut Mary */
@media only screen and (min-width: 428px) {
    .account-profile-container-mobile {
        /* margin: 10px; */
        /* width: 100vw;
        height: 100vh; */
        /* justify-content: center;
        align-items: center; */
        margin-top: 60px;
        margin-bottom: 65px;
    }
}


.account-profile-container-mobile {
    /* margin: 10px; */
    /* width: 100vw;
    height: 100vh; */
    /* justify-content: center;
    align-items: center; */
    margin-top: auto;
    margin-bottom: auto;
}

.profile-account-button-mobile {
    width: 80vw;
    margin: 15px;
    background-color: pink;
    color:black;   
}

.pet-profile-button-mobile {
    /* display: block; */
    /* justify-content: center;
    align-items: center; */
    /* margin:10px; */
    width: 80vw;
    margin: 15px;
    background-color: pink;
    color:black;
    

}

.canvas-button-mobile {
    
    width: 80vw;
    margin: 15px;
    background-color: pink;
    color:black;

}

.logout-button-mobile {
    width: 80vw;
    margin: 15px;
    background-color: pink;
    color:black;
    
}